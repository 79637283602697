import { Route, Routes } from 'react-router-dom';

import { Footer } from '@components/footer';

import { Navbar } from '@pages/@common/nav-bar';
const SolarDesigns = dynamic(() => import('@pages/solar-designs').then((m) => m));
const TermsAndConditions = dynamic(() => import('@pages/terms-and-conditions').then((m) => m));
const SignUp = dynamic(() => import('@pages/sign-up').then((m) => m));
const SignIn = dynamic(() => import('@pages/sign-in').then((m) => m));
const PrivacyPolicy = dynamic(() => import('@pages/privacy-policy').then((m) => m));
const PasswordRecovery = dynamic(() => import('@pages/password-recovery').then((m) => m));
const NotFound = dynamic(() => import('@pages/not-found').then((m) => m));
const Home = dynamic(() => import('@pages/home').then((m) => m));
const ForgetPassword = dynamic(() => import('@pages/forget-password').then((m) => m));
const Admin = dynamic(() => import('@pages/admin-page').then((m) => m));

import { withAutorized } from '@utils/hoc/withAutorized';
import { withPageProviders } from '@utils/hoc/withPageProviders';
import { dynamic } from '@utils/makeLazy';

const AutorizedAdmins = withAutorized(['Admin', 'SuperAdmin']);

export const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/signup" element={<SignUp />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="/privacy_policy" element={<PrivacyPolicy />} />

        <Route path={'/solar-designs/*'} element={<SolarDesigns />} />

        <Route path="/login" element={<SignIn />} />

        <Route path="/forgetpassword" element={<ForgetPassword />} />

        <Route path="/passwordrecovery" element={<PasswordRecovery />} />

        <Route path="/admin" element={withPageProviders(<Admin />, AutorizedAdmins)} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
};

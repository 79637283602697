import { Link } from 'react-router-dom';

import { Button } from '@components/button';

import logo from '@assets/logo12.png';

import { StyleProps } from '@app-types/general';
import SvgFacebookF from '@icons/FacebookF';
import SvgInstagram from '@icons/Instagram';
import SvgLinkedinIn from '@icons/LinkedinIn';
import SvgTwitter from '@icons/Twitter';

export const Footer = () => {
  const renderIcon = (Component: React.FC<StyleProps>, href: string) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="flex items-center justify-center size-9 bg-white rounded-full"
      >
        <Component className="size-6 fill-sky-800" />
      </a>
    );
  };

  return (
    <footer className="bg-stone-900 p-5 text-gray-200">
      <div className="flex flex-col lg:flex-row gap-6 items-center lg:items-start justify-around">
        <div className="flex flex-col items-center">
          <img
            src={logo}
            alt="Bees And Solar Logo"
            className="mb-3"
            style={{ maxWidth: '150px' }}
          />
          <Button label="Contact Us" rounded variant="success" />
        </div>

        <div className="flex flex-col items-center lg:items-start">
          <h5 className="text-3xl font-bold mb-2">GET IN TOUCH</h5>

          <p className="mb-1">COMMERCIAL SOLAR INFORMATION</p>
          <p className="text-yellow-500 mb-2">(210) 555-0132</p>

          <p className="mb-1">INVESTOR/ CORPORATE INFORMATION</p>
          <p className="text-yellow-500 mb-2">(210) 555-0444</p>

          <div className="flex items-center gap-4">
            {renderIcon(SvgFacebookF, 'https://www.facebook.com/')}
            {renderIcon(SvgTwitter, 'https://www.twitter.com/')}
            {renderIcon(SvgLinkedinIn, 'https://www.linkedin.com/')}
            {renderIcon(SvgInstagram, 'https://www.instagram.com/')}
          </div>
        </div>

        <div className="flex flex-col gap-3 items-center lg:items-start">
          <Link to="/privacy_policy">Privacy Policy</Link>
          <Link to="/terms-and-conditions">Terms of Service</Link>

          <p>COPYRIGHT © 2024. ALL RIGHTS RESERVED</p>

          <span
            className="cursor-pointer hover:text-white"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Back To Top ↑
          </span>
        </div>
      </div>
    </footer>
  );
};
